import { defineStore } from 'pinia'
import { PublicClientApplication } from "@azure/msal-browser";
import * as msal from "@azure/msal-browser"

const apiScopes = [`https://expertimegreenops.onmicrosoft.com/${process.env.VUE_APP_SCOPES}`];

export default defineStore('authStore', {
    state: () => ({
        msalInstance: null,
        msalConfig: {
            auth: {
                clientId: `${process.env.VUE_APP_CLIENT_ID}`,
                authority:
                    'https://expertimegreenops.b2clogin.com/expertimegreenops.onmicrosoft.com/B2C_1_SIGNIN_SIGNUP',
                knownAuthorities: ['expertimegreenops.b2clogin.com'],
                redirectUri: '/lobby',
                postLogoutRedirectUri: '/',
                navigateToLoginRequestUrl: false
            },
            cache: {
                cacheLocation: 'localStorage',
            },
        },
        maxAttempts: 3,
        pollingAccount: null,
        account: null,
        accountId: null,
        accessToken: '',
        me: null
    }),
    getters: {
        async getAccessToken() {
            if (this.accessToken) {
                return this.accessToken;
            }

            await this.getTokenRedirect([]);
        },
        async getMe(state) {
            return state.me;
        }
    },
    actions: {
        async initStore() {
            this.msalInstance = new PublicClientApplication(this.msalConfig);
            this.getAccounts();
            this.msalInstance.handleRedirectPromise();
        },

        async getAccounts(){
            const myAccounts = this.msalInstance.getAllAccounts();
            if (myAccounts.length > 0) {
                this.account = myAccounts.filter(acc => acc.environment == this.msalConfig.auth.knownAuthorities[0])[0];
                this.accountId = this.account.homeAccountId;
                this.msalInstance.setActiveAccount(this.account);
            }
        },

        async login() {
            this.msalInstance.handleRedirectPromise().then(async () => {
                const myAccounts = this.msalInstance.getAllAccounts();
                if (myAccounts.length === 0) {
                    // no accounts signed-in, attempt to sign a user in
                    await this.msalInstance.loginRedirect({ scopes: ["openid"] })
                } else if (myAccounts.length > 0) {
                    this.account = myAccounts.filter(acc => acc.environment == this.msalConfig.auth.knownAuthorities[0])[0];
                    this.accountId = this.account.homeAccountId;
                    await this.getTokenRedirect();
                    this.$router.push("/lobby");
                }
            });
        },

        async logout() {
            await this.msalInstance.logoutRedirect({ account: this.account })
        },

        async getTokenRedirect() {
            const silentRequest = { scopes: apiScopes, account: this.account };
            try {
                const authResult = await this.msalInstance.acquireTokenSilent(silentRequest);
                // todo (authResult);
                // In case the response from B2C server has an empty accessToken field
                // throw an error to initiate token acquisition
                if (!authResult.accessToken || authResult.accessToken === "") {
                    throw new msal.InteractionRequiredAuthError;
                } else {
                    this.accessToken = authResult.accessToken;
                    return authResult.accessToken;
                }
            } catch (error) {
                console.error("Silent token acquisition fails. Acquiring token using redirect. \n", error);
                if (error instanceof msal.InteractionRequiredAuthError) {
                    // fallback to interaction when silent call fails
                    const authResult = await this.msalInstance.acquireTokenRedirect(silentRequest);
                    this.accessToken = authResult.accessToken;
                    return authResult.accessToken;
                }
            }
        },

        async setMe(accountName) {
            await this.getTokenRedirect([]);
            try {
                const options = {
                    method: 'GET',
                    headers: { 'Authorization': `Bearer ${this.accessToken}` }
                }

                const response = await fetch(`${process.env.VUE_APP_BASE_API_URL}/api/${accountName}/me`, options);
                this.me = await response.json();
            } catch (error) {
                console.error(error);
            }
        }
    },
})